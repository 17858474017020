import React from 'react';
import DemoDigitalS from '../../Components/GetDemo/demoDigitalS/demodigitalS';

export default function UserdemoDigitalShelf({ logo, back,leftnavbar }) {
  return (
    <div>
      <DemoDigitalS logo={logo} back={back} leftnavbar={leftnavbar} />
    </div>
  )
}
