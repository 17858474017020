import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Styles from "./LeftNavbar.module.scss";
import { MenuItem } from '../MenuButtons/menuButtons';
import { digitalShelf, Home, OneClogo, Forecating, Categoryinsight, Settings, Notifications, Logout, OneClogoFullWidth } from '../../icon/IconConstants';

export default function LeftNavbar() {
    const [isComponentOvered, setIsComponentOvered] = useState(false);
    const [selected, setSelected] = useState(false);

    const location = useLocation();

    const determineSelected = (pathname) => {
        const normalizedPathname = pathname?.trim().toLowerCase();
        return staticLinks.findIndex(link => link?.url?.trim().toLowerCase() === normalizedPathname);
    };

    React.useEffect(() => {
        setSelected(determineSelected(location.pathname));
    }, [location.pathname]);

    const staticLinks = [
        { id: 1, text: 'Home', url: '/home', img: Home },
        { id: 2, text: 'Digital Shelf', url: '/digitalshelf', img: digitalShelf },
        { id: 3, text: 'Category Insights', url: '/categoryinsight', img: Categoryinsight },
        { id: 4, text: 'Forecasting', url: '/forecasting', img: Forecating },
        { id: 5, text: 'Settings', img: Settings },
        { id: 6, text: 'Notifications', img: Notifications },
        { id: 7, text: 'Logout', url: '/', img: Logout },
    ];

    const getDynamicSVG = (icon, index, selectedIndex) => {
        const color = index === selectedIndex ? '#fff' : '#000';
        return icon
            .replace(/fill="([^none"]*)"/g, `fill="${color}"`)  // Replace existing fill, but not "none"
            .replace(/stroke="([^none"]*)"/g, `stroke="${color}"`)
    };


    const HandleMouseOver = (state) => {
        setIsComponentOvered(state)
    }
    
    return (
        <>
            <div className={Styles.leftNavBar} id="nav-bar" onMouseOver={() => HandleMouseOver(true)} onMouseOut={() => HandleMouseOver(false)} >
                <div className={Styles.logo_name}>
                    <span className={Styles.lion_icon} dangerouslySetInnerHTML={{ __html: OneClogo }} ></span>
                    <div className={Styles.logo_text}>
                        <span dangerouslySetInnerHTML={{ __html: OneClogoFullWidth }}></span>
                        &nbsp;
                    </div>
                </div>
                <div className={Styles.nav_list}>
                    <div className={Styles.top_list}>

                        {staticLinks.slice(0, 4).map((link, index) => (
                            <MenuItem
                                key={link.id} // Use the unique identifier (id) as the key
                                index={index}
                                selected={selected === index}
                                setSelected={setSelected}
                                icon={getDynamicSVG(link.img, index, selected)} // Pass selected index
                                label={link.text}
                                expand={isComponentOvered}
                                url={link.url}
                                subMenu={link?.subMenu}
                            />
                        ))}

                    </div>
                    <div className={Styles.bottomIcons} >

                        {staticLinks.slice(4).map((link, index) => (
                            <MenuItem
                                key={link.id} // Use the unique identifier (id) as the key
                                index={index + 4} // Adjusting index to start from 5
                                selected={selected === index + 4} // Adjusting selected index to start from 5
                                setSelected={setSelected}
                                icon={getDynamicSVG(link.img, index + 4, selected)}  // Pass selected index
                                label={link.text}
                                expand={isComponentOvered}
                                url={link.url}
                                subMenu={link?.subMenu}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
