import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getlocalStorage } from './createSession';
import { useFetchUserDataPeriodically } from '../../utils/utils';


const INACTIVITY_TIMEOUT = 1 * 60 * 60 * 1000; // 1 hour in milliseconds adjust time 

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [display, setDisplay] = useState(false);
    const token = getlocalStorage("token");

    let inactivityTimer = null;

    const resetInactivityTimer = () => {
        if (inactivityTimer) {
            clearTimeout(inactivityTimer);
        }

        inactivityTimer = setTimeout(() => {
            navigate("/signin",
                // { state: { message: 'Session expired due to inactivity. Please log in again.' } }
            );
        }, INACTIVITY_TIMEOUT);
    };

    // const checkSessionExpiration = () => {
    //     if (!loginTime || isNaN(loginTime)) {
    //         console.log("Invalid login time or no login time found. Session expired.");
    //         return true; // Invalid login time, consider session expired
    //     }
    //     const currentTime = Date.now();
    //     const sessionRemainingTime = SESSION_TIMEOUT - (currentTime - loginTime);
    //     const isExpired = currentTime - loginTime > SESSION_TIMEOUT;
    //     // Log session status and remaining time
    //     console.log(`Login time: ${new Date(loginTime).toLocaleString()}`);
    //     console.log(`Current time: ${new Date(currentTime).toLocaleString()}`);
    //     console.log(`Time remaining until session expires: ${Math.max(sessionRemainingTime, 0)}ms`);
    //     if (isExpired) {
    //         console.log("Session has expired.");
    //     } else {
    //         console.log("Session is still valid.");
    //     }
    //     return isExpired;
    // };


    // Effect to manage session validity and inactivity timeout
    useEffect(() => {
        if (!token) {
            navigate("/signin",
                // { state: { message: 'Session expired or not authenticated. Please log in.' } }
            );
            return;
        }

        setDisplay(true);

        // Listen for user activity events (mousemove, keydown, etc.)
        const events = ['mousemove', 'keydown', 'scroll'];

        events.forEach((event) => {
            window.addEventListener(event, resetInactivityTimer);
        });

        resetInactivityTimer();
        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, resetInactivityTimer);
            });

            if (inactivityTimer) {
                clearTimeout(inactivityTimer);
            }
        };
    }, [token, navigate]);

    const fetchUserDataInterval = useFetchUserDataPeriodically();
    useEffect(() => {
        const interval = fetchUserDataInterval();
        return () => clearInterval(interval);
    }, [fetchUserDataInterval]);

    if (!display) {
        return <div>Loading...</div>;
    }

    return (
        <>
            {children}
        </>
    );
};

export default ProtectedRoute;