import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SSOLoginApi, getUserData } from '../../slices/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../slices/message/messageSlice';
import { Loader } from '../../icon/IconConstants';
import '../../styles/global.scss';
import { setlocalStorage } from '../AuthUser/createSession';

const DecryptComponent = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { SSOloginUserDetails, getUserDataDetails } = useSelector((store) => store.auth);

    const params = new URLSearchParams(location.search);
    const encryptedText = params.get('param');
    const resultEncryptedText = encryptedText ? encryptedText.replace(/ /g, '+') : null;

    useEffect(() => {
        if (resultEncryptedText) {
            params.delete('param');
            dispatch(SSOLoginApi({ encryptedData: resultEncryptedText }));
            // Remove 'param' from the URL in the browser's history
            const newUrl = `${window.location.pathname}?${params.toString()}`;
            window.history.replaceState(null, '', newUrl);
        } else {
            navigate('/');
        }

    }, [resultEncryptedText]);

    useEffect(() => {
        if (SSOloginUserDetails) {
            if (SSOloginUserDetails?.statusCode === '2000') {
                dispatch(getUserData())
            } else {
                dispatch(setMessage('Invalid Credentials !'));
                navigate('/');
            }
        }
    }, [SSOloginUserDetails]);

    useEffect(() => {
        if (getUserDataDetails) {
            if (getUserDataDetails?.statusCode === '2005') {
                navigate('/home');
                setlocalStorage('loginTime', Date.now());
            } else if (getUserDataDetails.statusCode !== '3006') {
                dispatch(setMessage('Something went wrong or Network issue!'));
                navigate('/');
            }
        }
    }, [getUserDataDetails]);


    return (
        <div className='align_center'>
            <div>
                <p className='align_center_text'>Loading...</p>
                <span dangerouslySetInnerHTML={{ __html: Loader }}></span>
            </div>
        </div>);
};

export default DecryptComponent;